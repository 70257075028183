export const appUpdateSnackbar = {
  MESSAGE: 'A new version available',
  ACTION_BUTTON_TITLE: 'Update',
};

export const offlineSnackbar = {
  MESSAGE: "We're offline",
};

export const offlineNotification = {
  TITLE: "We're offline",
  MESSAGE: 'There is no internet connection',
};

export const toolButtons = {
  LOGOUT_BUTTON_TOOLTIP_TITLE: 'Log out',
};

export const authPage = {
  TITLE_LOGIN: 'Log In',
  TITLE_SIGNUP: 'Sign Up',
  LOGIN_BUTTON_TITLE: 'Already registered',
  SIGNUP_BUTTON_TITLE: 'Create an account',
};

export const authForm = {
  CODE_INPUT_LABEL: 'Access key',
  NAME_INPUT_LABEL: 'Name',
  EMAIL_INPUT_LABEL: 'Email',
  PSWRD_INPUT_LABEL: 'Password',
  PHONES_NOTIFICATION: 'Message for the passengers',
  PHONE_PLACEHOLDER: '067 000 0000',
  PHONE_INPUT_LABEL: 'Phone number',
  PHONE_ADD_INPUT_LABEL: 'Extra phone number',
  LOGIN_SUBMIT_BUTTON_TITLE: 'Login',
  SIGNUP_SUBMIT_BUTTON_TITLE: 'Signup',
};

export const pageTitle = {
  ROUTES: 'Routes',
};

export const travelList = {
  IRRELEVANT_LIST_TITLE: 'With an irrelevant date',
  EMPTY_LIST_NOTIFICATION_TITLE: 'There are no routes',
  EMPTY_LIST_NOTIFICATION_MESSAGE: 'Add a new one',
  EMPTY_LIST_NOTIFICATION_BUTTON_TITLE: 'Create',
};

export const travel = {
  TITLE_CREATE: 'Create a route',
  TITLE_EDIT: 'Edit the route',
};

export const travelCardHeader = {
  EDIT_BUTTON_ARIA_LABEL: 'Reserve seats',
};

export const travelCardBar = {
  ADD_BUTTON_ARIA_LABEL: 'Reserve seats',
};

export const travelForm = {
  MIN_DATE_ERR: "Loks like you're going to travel in the past",

  SUBMIT_CREATE_TITLE: 'Create',
  SUBMIT_UPDATE_TITLE: 'Update',
  CANCEL_BUTTON_TITLE: 'Cancel',
  DELETE_BUTTON_TITLE: 'Remove',
};

export const orderListItem = {
  DIALOG_TITLE: 'Cancel order',
  DIALOG_MESSAGE: 'Do you accept order cancelling?',
  DIALOG_ACCEPT_BUTTON_TITLE: 'Yes',
  DIALOG_CANCEL_BUTTON_TITLE: 'Go back',
  DELETE_BUTTON_ARIA_LABEL: 'Order cancelation',
};

export const createOrder = {
  RESERVATION_TITLE: 'Reserve Seats',
  DEFAULT_PHONE_NUMBER: '+1 000 000 0000',
  PHONE_INPUT_ERROR_MESSAGE: 'Invalid phone number',
  NAME_FIELD_LABEL: 'Name',
  PHONE_FIELD_LABEL: 'Phone number',
  NOTIFICATION_FIELD_LABEL: 'Message to driver',
  RESERVATION_SUBMIT_TITLE: 'Reserve',
  RESERVATION_CANCEL_TITLE: 'Close',
  NO_SEATS_TITLE: 'All seats are reserved',
  AVAILABLE_SEATS_TITLE: 'seats',
  CURRENCY: 'USD',
};

export const createOrderTools = {
  INCREASE_BUTTON_ARIA_LABEL: 'increase amount',
  DECREASE_BUTTON_ARIA_LABEL: 'decrease amount',
};

export const csFAB = {
  ARIA_LABEL: 'new route',
};
