import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from '../hooks/useAuth';
import { useOrder } from '../hooks/useOrder';
import socketio from '../../socketio';

const TravelContext = createContext({
  travelList: [],
  travelToEdit: {},
  setTravelList: () => {},
  setTravelToEdit: () => {},
});

export const useTravelData = () => {
  return useContext(TravelContext);
};

export const TravelDataProvider = ({ children }) => {
  const [travelList, setTravelList] = useState();
  const [travelToEdit, setTravelToEdit] = useState();

  const { driverId } = useAuth();
  const { addOrder, cancelOrder } = useOrder(travelList, setTravelList);

  const syncTravel = useCallback(
    (action, data) => {
      let updTravels = [...travelList];

      const add = (travel) => {
        updTravels.push(travel);
        setTravelList(updTravels);
      };

      const upd = (travel) => {
        let index = updTravels.findIndex((t) => t.id === travel.id);
        if (index !== -1) {
          updTravels[index] = travel;
          setTravelList(updTravels);
        }
      };

      const del = (id) => {
        updTravels = updTravels.filter((t) => t.id !== id);
        setTravelList(updTravels);
      };

      switch (action) {
        case 'create':
          add(data);
          break;
        case 'update':
          upd(data);
          break;
        case 'delete':
          del(data);
          break;
        default:
          throw new Error('Invalid action name');
      }
    },
    [travelList, setTravelList]
  );

  // travelList socket
  useEffect(() => {
    const cb = (data) => {
      if (driverId && data.data && data.data.creatorId) {
        driverId === data.data.creatorId && syncTravel(data.action, data.data);
      }
    };
    socketio.on('travels', cb);

    return () => {
      socketio.off('travels', cb);
    };
  }, [driverId, syncTravel]);

  // order socket
  useEffect(() => {
    const cb = (action) => {
      const { mode, data } = action;
      switch (mode) {
        case 'add':
          addOrder(data.travelId, data.order);
          break;
        case 'cancel':
          cancelOrder(data.travelId, data.orderId, data.updSeatsReserved);
          break;
        default:
          throw new Error('Invalid mode');
      }
    };
    socketio.on('orders', cb);

    return () => {
      socketio.off('orders', cb);
    };
  }, [addOrder, cancelOrder]);

  return (
    <TravelContext.Provider
      value={{
        travelList,
        travelToEdit,
        setTravelList,
        setTravelToEdit,
      }}
    >
      {children}
    </TravelContext.Provider>
  );
};
