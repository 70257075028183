import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import './index.scss';

render(
  // StrictMode was disabled because of <Keyboard[Date/Time]Picker /> findDOMNode issue
  // <React.StrictMode>
  <Router>
    <App />
  </Router>,
  // </React.StrictMode>
  document.getElementById('root')
);

// serviceWorker.register(); - moved to useUpdate hook
